import React from 'react';
import SocialContainer from '../social/social-container';
import { useStaticQuery, graphql } from 'gatsby';
import { FaWhatsapp, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const Phone = () => {
    const data = useStaticQuery(graphql`
        query {
            contentfulAbout {
                whatsapp
                phone
                mail
            }
        }
    `);
    const { phone, whatsapp, mail } = data.contentfulAbout;
    return (
        <div className="p-3">
            <h4 className="mb-4">لا تتردد في الاتصال بنا</h4>
            <div className="phone-numbers mb-4">
                <div className="d-flex justify-content-between align-items-center my-4">
                    <h5 className="m-0">رقم الهاتف:</h5>
                    <div className="d-flex flex-wrap justify-content-end">
                        {phone.map((p) => (
                            <a className="btn" key={p} href={`tel:${p}`}>
                                {p}

                                <FaPhoneAlt className="mr-2" />
                            </a>
                        ))}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center my-4">
                    <h5 className="m-0">رقم الواتساب:</h5>
                    <div className="d-flex flex-wrap justify-content-end">
                        {whatsapp.map((w) => (
                            <a
                                key={w}
                                className="btn"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://wa.me/${w}`}>
                                {w}

                                <FaWhatsapp className="mr-2" />
                            </a>
                        ))}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center my-4">
                    <h5 className="m-0">البريد الالكتروني:</h5>
                    <a className="btn" href={`mailto:${mail}`}>
                        {mail}

                        <FaEnvelope className="mr-2" />
                    </a>
                </div>
            </div>
            <div className="mt-5">
                <h4 className="mb-4">تابعنا على المنصات التالية</h4>
                <SocialContainer />
            </div>
        </div>
    );
};
export default Phone;
