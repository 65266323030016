import React from 'react';
import Email from '../components/Contact/email';
import Phone from '../components/Contact/phone';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Contact = ({ data }) => {
    return (
        <Layout>
            <Seo title="للتواصل معنا وحجز الجلسات او الاستفسار" description="Contact us to request a quote!" />
            <div className="container my-5">
                <h2 className="latest-heading py-5 m-0 text-center">
                    <span>للتواصل معنا وحجز الجلسات او الاستفسار</span>
                </h2>
                <div className="row container-white">
                    <section className="col-md-6 my-4">
                        <Phone />
                    </section>
                    <section className="col-md-6 mt-4">
                        <div className="p-3">
                            <h4 className="mb-4">للاستفسار يمكنك تعبئة النموذج وارساله.</h4>
                            <Email />
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    );
};
export default Contact;
