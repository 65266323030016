import React from 'react';
import { emailStrings } from './strings';

export default class MyForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: '',
        };
    }
    render() {
        const { status } = this.state;
        return (
            <form onSubmit={this.submitForm} action="https://formspree.io/f/mpzobndz" method="POST">
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        placeholder={emailStrings.namePlaceholder}
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder={emailStrings.emailPlaceholder}
                    />
                </div>
                <div className="form-group">
                    <textarea
                        name="message"
                        id="message"
                        rows="10"
                        className="form-control"
                        placeholder={emailStrings.messagePlaceholder}
                    />
                </div>
                <div className="my-3 text-center">
                    {status === 'SUCCESS' ? (
                        <p>Thanks!</p>
                    ) : (
                        <button type="submit" className="link px-3">
                            {emailStrings.submitText}
                        </button>
                    )}
                </div>
                {status === 'ERROR' && <p>Ooops! There was an error.</p>}
            </form>
        );
    }

    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ status: 'SUCCESS' });
            } else {
                this.setState({ status: 'ERROR' });
            }
        };
        xhr.send(data);
    }
}
